import { FaRupeeSign, FaArrowLeft, FaTrash } from 'react-icons/fa'; // Import necessary icons
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Button, Card, Row, Col, Table, Modal ,Spinner} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import { useCart } from '../CartContext'; // Import useCart
import './CheckoutPage.css'; // Import custom styles
import { server } from './Server';
const CheckoutPage = () => {
  const { cart, user, updateQuantity, removeItem } = useCart(); // Access cart and user from CartContext
  const navigate = useNavigate();
  const [totalPrice, setTotalPrice] = useState(0);
  const [cgst, setCgst] = useState(0);
  const [sgst, setSgst] = useState(0);
  const [otherchare, setothercharge] = useState(0);
  const [totalPay, setTotalPay] = useState(0);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('cod'); // Default to Cash on Delivery
  const [showModal, setShowModal] = useState(false);
  const [modalOrderDetails, setModalOrderDetails] = useState(null);
  const [loading, setLoading] = useState(false);
useEffect(() => {
  if (cart && cart.length > 0) {
    // Calculate the total price
    const price = cart.reduce((total, item) => total + item.productPrice * item.quantity, 0);
    setTotalPrice(price);

    // Calculate CGST and SGST based on each item's shop details
    const totalCgst = cart.reduce((total, item) => {
      const shopCgst = item.shop?.cgst || 0; // Use shop's CGST value or default to 0
      return total + (item.productPrice * item.quantity * (shopCgst / 100));
    }, 0);

    const totalSgst = cart.reduce((total, item) => {
      const shopSgst = item.shop?.sgst || 0; // Use shop's SGST value or default to 0
      return total + (item.productPrice * item.quantity * (shopSgst / 100));
    }, 0);
    const totalother = cart.reduce((total, item) => {
      const shopSgst = item.shop?.deliveryCharge || 0; // Use shop's SGST value or default to 0
      return total + (item.productPrice * item.quantity * (shopSgst / 100));
    }, 0);

    setCgst(totalCgst);
    setSgst(totalSgst);
    setothercharge(totalother);
    setTotalPay(price + totalCgst + totalSgst + totalother);
  }
}, [cart]);


const handleCheckout = () => {
  const orderDetails = {
    user: user,
    items: cart.map(item => ({
      productId: item._id,
      productName: item.name,
      quantity: item.quantity,
      price: item.productPrice,
    })),
    totalAmount: totalPay,
    paymentMethod: selectedPaymentMethod 
  };
  setModalOrderDetails(orderDetails);
  setShowModal(true);
};

const handleModalConfirm = async () => {
  setLoading(true);

  try {
    // Get customer location
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(async (position) => {
        const { latitude, longitude } = position.coords;
        const amountInPaise = Math.round(totalPay * 100);
        const shopId = cart.length > 0 ? cart[0].shop.shopId : null;

        // Create the order
        const orderDetails = {
          user: user,
          items: cart.map(item => ({
            productId: item._id,
            productName: item.name,
            quantity: item.quantity,
            price: item.productPrice,
          })),
          totalAmount: totalPay,
          paymentMethod: selectedPaymentMethod,
          shopId: shopId,
          location: {
            lat: latitude,
            lng: longitude,
          }, // Added customer location here
        };

        const orderResponse = await axios.post(`${server}/order/createorder`, orderDetails);

        if (orderResponse.status === 201) {
          const { _id: orderId, orderNo, orderStatus, items } = orderResponse.data.order;

          if (selectedPaymentMethod === 'cod') {
            // Store order details in local storage
            localStorage.setItem('orderDetails', JSON.stringify({
              orderNo,
              orderId,
              totalAmount: totalPay,
              paymentMethod: selectedPaymentMethod,
              orderStatus,
              items,
              paymentStatus: 'Pending',
            }));
            navigate('/order-success');
          } else if (selectedPaymentMethod === 'card') {
            // Initiate payment with Razorpay
            const paymentResponse = await axios.post(`${server}/payment/orders`, {
              amount: amountInPaise,
              currency: 'INR',
            });

            const { id: razorpayOrderId, amount: amountToPay, currency } = paymentResponse.data;

            const options = {
              key: 'rzp_test_G2NerVFx2fw3bq',
              amount: amountToPay,
              currency: currency,
              name: "foodie",
              description: "Payment for Order",
              order_id: razorpayOrderId,
              handler: async (response) => {
                const paymentDetails = {
                  user: user,
                  orderNo: orderNo,
                  paymentId: response.razorpay_payment_id,
                  signature: response.razorpay_signature,
                  razorpayOrderId: razorpayOrderId,
                  amount: amountToPay,
                  currency: currency,
                  paymentMethod: 'Razorpay',
                  status: 'Success',
                };

                try {
                  const paymentSaveResponse = await axios.post(`${server}/payment/success`, paymentDetails);

                  if (paymentSaveResponse.status === 201) {
                    await axios.put(`${server}/order/updateorder/${orderId}`, {
                      paymentStatus: 'Success',
                    });

                    // Store order details in local storage
                    localStorage.setItem('orderDetails', JSON.stringify({
                      orderNo,
                      totalAmount: totalPay,
                      paymentMethod: selectedPaymentMethod,
                      paymentStatus: 'Success',
                    }));

                    navigate('/order-success');
                  }
                } catch (error) {
                  console.error('Error updating order:', error);
                  alert('Failed to update order. Please try again.');
                }
              },
              prefill: {
                name: user.name,
                email: user.email,
                contact: user.phone,
              },
              notes: {
                address: "Your order address",
              },
              theme: {
                color: "#F37254",
              },
            };

            const razorpay = new window.Razorpay(options);
            razorpay.open();
          }
        } else {
          alert('Failed to create order. Please try again.');
        }
      }, (error) => {
        console.error('Error fetching location:', error);
        alert('Failed to get location. Please enable location services and try again.');
      });
    } else {
      alert('Geolocation is not supported by this browser.');
    }
  } catch (error) {
    console.error('Error during checkout:', error);
    alert('Failed to proceed to checkout. Please try again.');
  } finally {
    setLoading(false);
  }
};



  
  
  
  
  
  const handleModalClose = () => {
    setShowModal(false);
  };

  const handleContinueShopping = () => {
    navigate('/'); // Navigate back to shopping page
  };

  const handleGoBack = () => {
    navigate(-1); // Go back to the previous page
  };

  const increaseQuantity = (productId) => {
    const item = cart.find(item => item._id === productId);
    if (item) {
      updateQuantity(productId, item.quantity + 1);
    }
  };

  const decreaseQuantity = (productId) => {
    const item = cart.find(item => item._id === productId);
    if (item && item.quantity > 1) {
      updateQuantity(productId, item.quantity - 1);
    }
  };

  return (
    <div>
      <header className="fixed-top bg-light py-3">
        <div className="container d-flex justify-content-between align-items-center">
          <Button variant="link" onClick={handleGoBack} className="p-0" style={{ color: 'gray' }}>
            <FaArrowLeft style={{ fontSize: '1rem' }} />
          </Button>
          <h1 className="mb-0 text-center">My Order</h1>
          <h5 className="mb-0 text-end text-black border-b border-gray-200">
            {user ? user.name : 'Welcome, Guest'}
          </h5>
        </div>
      </header>

      <div className="container-fluid mb-5 mt-5 pt-5"> {/* Use container-fluid for full width */}
        <Row>
          <Col md={12}>
            {cart && cart.length > 0 ? (
              <>
                <Card className="mb-4">
                  <Card.Body>
                    <div className="d-flex justify-content-between align-items-center mb-3">
                      <Card.Title>Items Summary :</Card.Title>
                      <Button
                        variant="secondary"
                        onClick={handleContinueShopping}
                      >
                        Continue Shopping
                      </Button>
                    </div>
                    <Table striped bordered hover variant="white">
                      <thead>
                        <tr>
                          <th>Image</th>
                          <th>Product Name</th>
                          <th>Price</th>
                          <th>Quantity</th>
                          <th>Total</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {cart.map((item) => (
                          <tr key={item._id}>
                            <td>
                              <img
                                src={`http://localhost:5000/${item.image}`}
                                alt={item.name}
                                style={{ width: '80px', height: '80px' }}
                              />
                            </td>
                            <td>{item.name}</td>
                            <td>₹{item.productPrice.toFixed(2)}</td>
                            <td>
                              <div className="quantity-controls">
                                <Button
                                  className="quantity-button quantity-button-decrease"
                                  onClick={() => decreaseQuantity(item._id)}
                                  disabled={item.quantity === 1}
                                >
                                  -
                                </Button>
                                <span className="quantity-value">{item.quantity}</span>
                                <Button
                                  className="quantity-button quantity-button-increase"
                                  onClick={() => increaseQuantity(item._id)}
                                >
                                  +
                                </Button>
                              </div>
                            </td>
                            <td>₹{(item.productPrice * item.quantity).toFixed(2)}</td>
                            <td>
                              <Button
                                variant="danger"
                                onClick={() => removeItem(item._id)}
                                className="d-flex align-items-center"
                              >
                                <FaTrash />
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <div className="mt-4">
                      <Card.Title>Order Details:</Card.Title>
                      <hr />
                      <Row>
                        <Col>
                          <p>Items Total:</p>
                          <p>CGST {}:</p>
                          <p>SGST (9%):</p>
                          <p>other charge (9%):</p>
                        </Col>
                        <Col className="text-end">
                          <p>₹{totalPrice.toFixed(2)}</p>
                          <p>₹{cgst.toFixed(2)}</p>
                          <p>₹{sgst.toFixed(2)}</p>
                          <p>₹{otherchare.toFixed(2)}</p>
                        </Col>
                      </Row>
                      <hr />
                      <Row className="total-payable-section p-2">
                        <Col>
                          <Card.Title>Total Payable:</Card.Title>
                        </Col>
                        <Col className="text-end">
                          <h3 className="total-payable-amount">
                            <Card.Title>
                              ₹{totalPay.toFixed(2)}
                            </Card.Title>
                          </h3>
                        </Col>
                      </Row>
                    </div>
                  </Card.Body>
                </Card>

                <Card className="mb-4 payment-method-card">
  <Card.Body>
    <Card.Title className="payment-title">Select Payment Method:</Card.Title>
    <hr />
    <div className="payment-options">
      <label className="payment-option">
        <input
          type="radio"
          name="paymentMethod"
          value="cod"
          checked={selectedPaymentMethod === 'cod'}
          onChange={(e) => setSelectedPaymentMethod(e.target.value)}
        />
        Cash on Delivery
      </label>
      <label className="payment-option">
        <input
          type="radio"
          name="paymentMethod"
          value="card"
          checked={selectedPaymentMethod === 'card'}
          onChange={(e) => setSelectedPaymentMethod(e.target.value)}
          disabled
        />
        Credit/Debit Card
      </label>
    </div>
  </Card.Body>
</Card>

                <Card.Footer className="d-flex fixed-footer justify-content-between">
                  <div className="total-price d-flex align-items-center" style={{ color: '#FF6F00' }}>
                    <FaRupeeSign style={{ marginRight: '5px' }} />
                    <span>{totalPay.toFixed(2)}</span>
                  </div>
                  <Button
      variant="primary"
      onClick={handleCheckout}
      className="me-2"
      disabled={loading} // Disable button while loading
    >
      {loading ? <Spinner animation="border" size="sm" /> : 'MAKE PAYMENT'}
    </Button>
                </Card.Footer>
              </>
            ) : (
              <Card>
                <Card.Body>
                  <Card.Title>No items in cart</Card.Title>
                  <Button onClick={handleContinueShopping}>Continue Shopping</Button>
                </Card.Body>
              </Card>
            )}
          </Col>
        </Row>
      </div>

      {/* Confirmation Modal */}
      {modalOrderDetails && (
        <Modal show={showModal} onHide={handleModalClose}>
          <Modal.Header closeButton>
            <Modal.Title>Confirm Your Order</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h5>Order Summary:</h5>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Product Name</th>
                  <th>Quantity</th>
                  <th>Price</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                {modalOrderDetails.items.map((item, index) => (
                  <tr key={index}>
                    <td>{item.productName}</td>
                    <td>{item.quantity}</td>
                    <td>₹{item.price.toFixed(2)}</td>
                    <td>₹{(item.price * item.quantity).toFixed(2)}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <Row>
              <Col><strong>Total Amount:</strong></Col>
              <Col className="text-end"><strong>₹{modalOrderDetails.totalAmount.toFixed(2)}</strong></Col>
            </Row>
            <Row className="mt-3">
              <Col><strong>Payment Method:</strong></Col>
              <Col className="text-end"><strong>{modalOrderDetails.paymentMethod}</strong></Col>
            </Row>
       
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleModalClose}>
              Cancel
            </Button>
            <Button
      variant="primary"
      onClick={handleModalConfirm}
      disabled={loading} // Disable button while loading
    >
      {loading ? <Spinner animation="border" size="sm" /> : 'Confirm Order'}
    </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};

export default CheckoutPage;
