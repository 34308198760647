import React, { useEffect, useState } from 'react';
import mainCourse from "../Images/maincourse.jpg";
import "../index.css"; // Custom CSS file for additional styling
import bannerImage from '../Images/maincourse.jpg'; // Import the new banner image
import '@fortawesome/fontawesome-free/css/all.min.css';
import vegIcon from '../Images/Vegicon.png'; // Import the vegetarian icon
import { useNavigate } from 'react-router-dom';
import AppBar from './AppBar';
import axios from 'axios';

const Banner = () => {
  return (
    <div className="relative w-full h-80 sm:h-96 overflow-hidden">
      <img
        src={bannerImage}
        alt="Promotional Banner"
        className="w-full h-full object-cover"
      />
      <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50">
        <div className="text-center text-white p-4">
          <h2 className="text-2xl md:text-3xl font-bold">Delicious Main Courses</h2>
          <p className="text-lg md:text-xl mt-2">Order now and enjoy our main courses!</p>
        </div>
      </div>
    </div>
  );
};

const MainCourseList = () => {
  const navigate = useNavigate();
  // const initialMainCourses = [
  //   {
  //     name: "Butter Chicken",
  //     categary:'maincourse',
  //     price: 495,
  //     discount: 10, // 10% discount
  //     description: "Rich and creamy butter chicken with a hint of spices...",
  //     image: mainCourse,
  //     quality: 2 // Quality rating out of 5
  //   },
  //   {
  //     name: "Paneer Butter Masala",
  //     categary:'maincourse',
  //     price: 425,
  //     discount: 15, // 15% discount
  //     description: "Delicious paneer butter masala in a creamy tomato sauce...",
  //     image: mainCourse,
  //     quality: 4 // Quality rating out of 5
  //   },
  //   {
  //     name: "Lamb Rogan Josh",
  //     categary:'maincourse',
  //     price: 699,
  //     discount: 20, // 20% discount
  //     description: "Aromatic and flavorful lamb rogan josh...",
  //     image: mainCourse,
  //     quality: 5 // Quality rating out of 5
  //   },
  // ];

  
  const [mainCourses, setMainCourses] = useState([]);
  const [cartItems, setCartItems] = useState([]);

  const increaseQuantity = (index) => {
    const newMainCourses = [...mainCourses];
    newMainCourses[index].quantity += 1;
    setMainCourses(newMainCourses);
    updateCartItems(newMainCourses);
  };

  const decreaseQuantity = (index) => {
    const newMainCourses = [...mainCourses];
    if (newMainCourses[index].quantity > 0) {
      newMainCourses[index].quantity -= 1;
    }
    setMainCourses(newMainCourses);
    updateCartItems(newMainCourses);
  };

  const updateCartItems = (newMainCourses) => {
    const newCartItems = newMainCourses.filter(mainCourse => mainCourse.quantity > 0);
    setCartItems(newCartItems);
  };

  const handleAddClick = (index) => {
    const newMainCourses = [...mainCourses];
    newMainCourses[index].quantity = 1;
    setMainCourses(newMainCourses);
    updateCartItems(newMainCourses);
  };

  const handleViewCart = () => {
    navigate('/place-order', { state: { cartItems, maincourse: mainCourses } });
  };

  const totalQuantity = cartItems.reduce((sum, item) => sum + item.quantity, 0);
  const totalPrice = cartItems.reduce((sum, item) => sum + (item.price * item.quantity * (1 - item.discount / 100)), 0).toFixed(2);

  const renderStars = (quality) => {
    const stars = [];
    for (let i = 0; i < 5; i++) {
      stars.push(
        <i key={i} className={`fas fa-star ${i < quality ? 'text-yellow-500' : 'text-gray-300'}`}></i>
      );
    }
    return stars;
  };

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get('http://localhost:5000/all');
      
        setMainCourses(response.data);
      
  
      } catch (error) {
        console.error(error);
      }
    };

    fetchProducts();
  }, []);




  return (
    <div className="flex flex-col h-screen">
      <AppBar />
      <div className="flex-1 overflow-auto">
        <Banner />
        <div className="text-3xl font-bold mb-4 text-center">Main Courses</div>
        <div className="grid grid-cols-1 gap-4">
          {mainCourses.map((mainCourse, index) => {
            const discountedPrice = mainCourse.price - (mainCourse.price * (mainCourse.discount / 100));
            return (
              <div key={index} className="card shadow-lg rounded-lg overflow-hidden">
                <div className="relative flex p-4 items-center">
                  <div className="relative sm:w-[20%] h-[90%]">
                    <img src={`http://localhost:5000/${mainCourse.image}`} className="w-full h-full object-cover rounded-md" alt={mainCourse.name} />
                    <img src={vegIcon} className="absolute top-1 left-1 w-6 h-6" alt="Vegetarian Icon" />
                  </div>
                  <div className="ml-4 flex-1">
                    <div className="flex items-center justify-between">
                      <h5 className="text-lg font-bold">{mainCourse.name}</h5>
                    </div>
                    <p className="text-gray-700 flex items-center">
                      <span className="line-through text-red-500">₹{mainCourse.price}</span> {" "}
                      <span className="text-green-500 ml-2">₹{discountedPrice.toFixed(2)}</span>
                      <span className="text-green-500 ml-2">({mainCourse.discount}%)</span>
                    </p>
                    <div className="flex justify-between items-center mt-2">
                      {mainCourse.quantity === 0 ? (
                        <button
                          className="px-4 py-2 bg-green-500 text-white rounded"
                          onClick={() => handleAddClick(index)}
                        >
                          Add
                        </button>
                      ) : (
                        <div className="flex items-center space-x-2">
                          <button
                            className="px-2 py-1 bg-red-500 text-white rounded"
                            onClick={() => decreaseQuantity(index)}
                          >
                            -
                          </button>
                          <span>{mainCourse.quantity}</span>
                          <button
                            className="px-2 py-1 bg-green-500 text-white rounded"
                            onClick={() => increaseQuantity(index)}
                          >
                            +
                          </button>
                        </div>
                      )}
                      <div className="flex items-center">
                        {renderStars(mainCourse.quality)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="fixed inset-x-0 bottom-0 bg-red-500 text-white p-2 flex justify-between items-center">
        <span className="text-lg font-bold">₹{totalPrice}</span>
        <button
          onClick={handleViewCart}
          className="text-white py-2 px-4 hover:bg-red-700 border rounded-full border-white flex items-center"
        >
          View Cart ({totalQuantity})
        </button>
      </div>
    </div>
  );
};

export default MainCourseList;
