import React from 'react';
import Menu from './Menu';
import Banner from './Banner';
import Navbar from './Navbar';
import AllItem from './AllItem'
import Footer from './Footer';
import Pizzalist from './pizzalist'
// import  GetCollectionData  from './CollectionDataPage';
const Body = () => {
  return (
    <div className='bg-grd'>
      <Navbar />
      <Banner />
      <Menu />
      <AllItem/>
      {/* <Pizzalist/> */}
    {/* <GetCollectionData/> */}
    <Footer/>
    </div>
  )
}

export default Body ;