import React from 'react';
import { useNavigate } from 'react-router-dom';
import '@fortawesome/fontawesome-free/css/all.min.css';

const AppBar = () => {
  const navigate = useNavigate();

  return (
    <div className="w-full bg-gray-800 text-white p-2 flex items-center">
      <button onClick={() => navigate(-1)} className="text-white">
        <i className="fas fa-arrow-left"></i>
      </button>
      <h1 className="text-xl text-center items-center justify-center ml-4">Foodie..</h1>
    </div>
  );
};
export default AppBar;
