import React from 'react';
import { FaFacebookF, FaTwitter, FaInstagram, FaYoutube } from 'react-icons/fa';
import 'bootstrap/dist/css/bootstrap.min.css';

const Footer = () => {
  return (
    <footer className="bg-dark text-white pt-5">
      <div className="container">
        <div className="row">
          {/* Logo and Description */}
          <div className="col-md-4 mb-4">
            <h2 className="h3 font-weight-bold">Foodie</h2>
            <p className="text-white">
              Your ultimate destination for delicious recipes, food tips, and the latest in culinary trends. Join our community of food lovers today!
            </p>
            <div className="d-flex justify-content-between align-items-center border-top border-secondary mt-5 pt-3">
              <a href="https://facebook.com" className="text-white mr-3">
                <FaFacebookF size={24} />
              </a>
              <a href="https://twitter.com" className="text-white mr-3">
                <FaTwitter size={24} />
              </a>
              <a href="https://instagram.com" className="text-white mr-3">
                <FaInstagram size={24} />
              </a>
              <a href="https://youtube.com" className="text-white">
                <FaYoutube size={24} />
              </a>
            </div>
          </div>

          {/* Quick Links */}
          <div className="col-md-4 mb-4">
            <h4 className="h5">Quick Links</h4>
            <ul className="list-unstyled">
              <li>
                <a href="/recipes" className="text-white">Recipes</a>
              </li>
              <li>
                <a href="/blog" className="text-white">Blog</a>
              </li>
              <li>
                <a href="/about-us" className="text-white">About Us</a>
              </li>
              <li>
                <a href="/contact" className="text-white">Contact Us</a>
              </li>
            </ul>
          </div>

          {/* Contact Information */}
          <div className="col-md-4">
            <h4 className="h5">Contact Us</h4>
            <p className="text-white mb-1">Email: <a href="mailto:support@foodiehaven.com" className="text-white">support@foodiehaven.com</a></p>
            <p className="text-white mb-1">Phone: <a href="tel:+919266540246" className="text-white">+91-9266540246</a></p>
            <p className="text-white">Address: 123 Foodie Street, Culinary City, FL 12345</p>
          </div>
        </div>

        {/* Footer Bottom */}
        <div className="text-center border-top border-secondary mt-5 pt-3">
          <p className="mb-0 text-white">&copy; 2024 Foodie. All Rights Reserved.</p>
          <p className="text">Designed with <span className="text-danger">&hearts;</span> by Foodie Haven Team</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
