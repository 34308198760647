import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useCart } from '../CartContext';
import { useParams } from 'react-router-dom';
import { server } from './Server';
const AllCollections = () => {
  const [collectionsData, setCollectionsData] = useState({});
  const [error, setError] = useState(null);
  const { cart, addToCart, removeFromCart } = useCart();
  const { category } = useParams(); // Get the category from URL params

  useEffect(() => {
    const fetchCollections = async () => {
      try {
        const response = await axios.get(`${server}/all-collections`);
        setCollectionsData(response.data);
      } catch (error) {
        console.error('Error fetching collections:', error);
        setError('Error fetching collections');
      }
    };

    fetchCollections();
  }, []);

  const handleAddClick = (item) => {
    const isInCart = cart.find(cartItem => cartItem._id === item._id);

    if (isInCart) {
      removeFromCart(item._id);
    } else {
      addToCart(item);
    }
  };

  const renderStars = (quality) => {
    return Array.from({ length: quality }).map((_, i) => (
      <span key={i} className="text-yellow-400 text-lg">⭐</span>
    ));
  };

  // Filter collections based on category
  const filteredCollections = category && collectionsData[category] ? collectionsData[category] : [];

  // Scroll to the section based on category
  useEffect(() => {
    if (category) {
      const section = document.getElementById(category);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [category]);

  return (
    <div className="container mx-auto p-6">
      {error && <p className="text-red-500 mb-4">{error}</p>}
      <hr className="mb-6 border-gray-300" />

      {Object.keys(collectionsData).map((collectionName, collectionIndex) => (
        <div key={collectionIndex} id={collectionName} className="mb-12">
          <h1 className="allmenu">{collectionName} Menu List</h1>
          <hr />
          <div className="grid grid-cols-1 gap-10 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
            {collectionsData[collectionName].map((item, index) => {
              const price = parseFloat(item.price) || 0;
              const productPrice = parseFloat(item.productPrice) || 0;
              const discountPercentage = price > 0 ? ((price - productPrice) / price) * 100 : 0;
              const discountedPrice = productPrice || price;

              return (
                <div key={index} className="card bg-white shadow-md rounded-lg overflow-hidden relative h-80 w-64">
                  <div className="relative w-full h-48">
                    <img 
                      src={item.image ? `http://localhost:5000/${item.image}` : '/path/to/placeholder.jpg'} 
                      className="w-full h-full object-cover" 
                      alt={item.name} 
                    />
                    {discountPercentage > 0 && (
                      <div className="absolute top-2 left-2 bg-red-500 text-white px-2 py-1 text-xs rounded-full shadow-md">
                        {discountPercentage.toFixed(0)}%
                      </div>
                    )}
                  </div>
                  <div className="p-4 h-32">
                    <h5 className="text-lg font-semibold mb-1">{item.name}</h5>
                    <p className="text-xs text-gray-500 mb-2">
                      Categories: {item.categories}
                    </p>
                    <p className="text-gray-700 mb-2">
                      {discountPercentage > 0 && (
                        <span className="line-through text-red-500">₹{price.toFixed(2)}</span>
                      )}
                      <br />
                      <span className="text-green-500 text-lg font-bold">₹{discountedPrice.toFixed(2)}</span>
                      {discountPercentage > 0 && (
                        <div className="text-red-500 text-sm mt-1">
                          Save {discountPercentage.toFixed(0)}%!
                        </div>
                      )}
                    </p>
                    <div className="flex items-center space-x-2 mb-2">
                      {renderStars(item.quality)}
                    </div>
                    <div className="flex items-center justify-between">
                      <button
                        className={`w-full px-4 py-2 ${cart.find(cartItem => cartItem._id === item._id) ? 'bg-red-500' : 'bg-green-500'} text-white rounded hover:bg-${cart.find(cartItem => cartItem._id === item._id) ? 'red-600' : 'green-600'} transition duration-200`}
                        onClick={() => handleAddClick(item)}
                        disabled={item.quantity <= 0}
                      >
                        {cart.find(cartItem => cartItem._id === item._id) ? 'Remove from Cart' : 'Add to Cart'}
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ))}
    </div>
  );
};

export default AllCollections;
