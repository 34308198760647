import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

const GetItem = () => {
  const [mainCourses, setMainCourses] = useState([]);
  const [filteredCourses, setFilteredCourses] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get('http://localhost:5000/all');
        setMainCourses(response.data);
        setFilteredCourses(response.data);

        // Extract unique categories
        const uniqueCategories = [...new Set(response.data.map(item => item.category))];
        setCategories(uniqueCategories);
      } catch (error) {
        console.error(error);
      }
    };

    fetchProducts();
  }, []);

  useEffect(() => {
    const filterCourses = () => {
      let filtered = mainCourses;

      if (searchQuery) {
        filtered = filtered.filter(course =>
          course.name.toLowerCase().includes(searchQuery.toLowerCase())
        );
      }

      if (selectedCategory) {
        filtered = filtered.filter(course => course.category === selectedCategory);
      }

      setFilteredCourses(filtered);
    };

    filterCourses();
  }, [searchQuery, selectedCategory, mainCourses]);

  const increaseQuantity = (index) => {
    const newCourses = [...filteredCourses];
    newCourses[index].quantity += 1;
    setFilteredCourses(newCourses);
  };

  const decreaseQuantity = (index) => {
    const newCourses = [...filteredCourses];
    if (newCourses[index].quantity > 0) {
      newCourses[index].quantity -= 1;
    }
    setFilteredCourses(newCourses);
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`http://localhost:5000/products/${id}`);
      setMainCourses(mainCourses.filter(course => course._id !== id));
      setFilteredCourses(filteredCourses.filter(course => course._id !== id));
    } catch (error) {
      console.error('Error deleting product:', error);
    }
  };

  const handleUpdate = async (id, updatedDetails) => {
    try {
      const response = await axios.put(`http://localhost:5000/products/${id}`, updatedDetails);
      const updatedCourses = mainCourses.map(course =>
        course._id === id ? response.data : course
      );
      setMainCourses(updatedCourses);
      setFilteredCourses(filteredCourses.map(course =>
        course._id === id ? response.data : course
      ));
    } catch (error) {
      console.error('Error updating product:', error);
    }
  };

  return (
    <div className="container mx-auto p-4 relative">
      {/* Button positioned in the top-right corner */}
      <Link to={'/additem'}>
        <button className="absolute top-4 right-4 px-4 py-2 bg-blue-500 text-white rounded shadow-lg">
          Add Product
        </button>
      </Link>

      <h1 className="text-2xl font-bold mb-4">Product List</h1>

      {/* Search and Filter Section */}
      <div className="mb-4">
        <input
          type="text"
          placeholder="Search by name..."
          className="px-4 py-2 border rounded-md mr-4"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <select
          className="px-4 py-2 border rounded-md"
          value={selectedCategory}
          onChange={(e) => setSelectedCategory(e.target.value)}
        >
          <option value="">All Categories</option>
          {categories.map(category => (
            <option key={category} value={category}>
              {category}
            </option>
          ))}
        </select>
      </div>

      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border border-gray-300">
          <thead>
            <tr>
              <th className="px-6 py-3 border-b text-left text-sm font-medium text-gray-700">Image</th>
              <th className="px-6 py-3 border-b text-left text-sm font-medium text-gray-700">Name</th>
              <th className="px-6 py-3 border-b text-left text-sm font-medium text-gray-700">Category</th>
              <th className="px-6 py-3 border-b text-left text-sm font-medium text-gray-700">Price</th>
              <th className="px-6 py-3 border-b text-left text-sm font-medium text-gray-700">Discount</th>
              <th className="px-6 py-3 border-b text-left text-sm font-medium text-gray-700">Description</th>
              <th className="px-6 py-3 border-b text-left text-sm font-medium text-gray-700">Quantity</th>
              <th className="px-6 py-3 border-b text-left text-sm font-medium text-gray-700">Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredCourses.map((course, index) => {
              const discountedPrice = course.price - (course.price * (course.discount / 100));
              return (
                <tr key={course._id}>
                  <td className="px-6 py-4 border-b">
                    <img src={`http://localhost:5000/${course.image}`} alt={course.name} className="w-16 h-16 object-cover rounded-md" />
                  </td>
                  <td className="px-6 py-4 border-b">{course.name}</td>
                  <td className="px-6 py-4 border-b">{course.category}</td>
                  <td className="px-6 py-4 border-b">
                    <span className="line-through text-red-500">₹{course.price}</span> <br />
                    <span className="text-green-500">₹{discountedPrice.toFixed(2)}</span>
                  </td>
                  <td className="px-6 py-4 border-b">{course.discount}%</td>
                  <td className="px-6 py-4 border-b">{course.description}</td>
                  <td className="px-6 py-4 border-b">
                    <div className="flex items-center space-x-2">
                      <button
                        className="px-2 py-1 bg-red-500 text-white rounded"
                        onClick={() => decreaseQuantity(index)}
                      >
                        -
                      </button>
                      <span className="mx-2">{course.quantity}</span>
                      <button
                        className="px-2 py-1 bg-green-500 text-white rounded"
                        onClick={() => increaseQuantity(index)}
                      >
                        +
                      </button>
                    </div>
                  </td>
                  <td className="px-6 py-4 border-b">
                    <div className="flex space-x-2">
                      <button
                        className="px-2 py-1 bg-blue-500 text-white rounded"
                        onClick={() => handleUpdate(course._id, { name: "Updated Name" })}
                      >
                        Update
                      </button>
                      <button
                        className="px-2 py-1 bg-red-500 text-white rounded"
                        onClick={() => handleDelete(course._id)}
                      >
                        Delete
                      </button>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default GetItem;
