// components/AddProduct.js
import React, { useState } from 'react';
import axios from 'axios';
import { server } from './Server';
const categories = ['Pizza', 'Tandoori', 'Chinies', 'Maincourse'];

const AddProduct = () => {

  const [formData, setFormData] = useState({
    name: '',
    category: '',
    price: '',
    discount: '',
    description: '',
    quantity: ''
  });
  const [image, setImage] = useState(null);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append('name', formData.name);
    data.append('category', formData.category);
    data.append('price', formData.price);
    data.append('discount', formData.discount);
    data.append('description', formData.description);
    data.append('quantity', formData.quantity);
    if (image) data.append('image', image);

    try {
      await axios.post('http://localhost:5000/add', data, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      alert('Product added successfully!');
      
    } catch (error) {
      console.error('Error adding product:', error);
      alert('Failed to add product.');
    }
  };
  return (
    <div className="container1">
      <h1>Add Product</h1>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          name="name"
          placeholder="Name"
          onChange={handleChange}
          required
        />
        <select
          name="category"
          onChange={handleChange}
          value={formData.category}
          required
        >
          <option value="">Select a Category</option>
          {categories.map((cat) => (
            <option key={cat} value={cat}>{cat}</option>
          ))}
        </select>
        <input
          type="number"
          name="price"
          placeholder="Price"
          onChange={handleChange}
          required
        />
        <input
          type="number"
          name="discount"
          placeholder="Discount"
          onChange={handleChange}
        />
        <textarea
          name="description"
          placeholder="Description"
          onChange={handleChange}
        ></textarea>
        <input
          type="number"
          name="quantity"
          placeholder="quantity"
          onChange={handleChange}
        />
        <input
          type="file"
          onChange={handleImageChange}
        />
        <button type="submit">Add Product</button>
      </form>
    </div>
  );
};

export default AddProduct;
