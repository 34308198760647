import React from 'react';
import { useNavigate } from 'react-router-dom';
import AppBar from './AppBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

const OrderSuccess = () => {
  const navigate = useNavigate();

  const handleGoHome = () => {
    navigate('/user/status');
  };

  return (
    <div>
      <AppBar />
      <div className="flex justify-center items-center min-h-screen">
        <div className="bg-white p-6 rounded-lg shadow-lg text-center">
          <FontAwesomeIcon icon={faCheckCircle} className="text-green-600 text-6xl mb-4" />
          <h2 className="text-3xl font-bold text-green-600 mb-4">Order Successful!</h2>
          <p className="text-lg text-gray-700 mb-6">Your order has been placed successfully. Thank you for shopping with us!</p>
          <button
            onClick={handleGoHome}
            className="bg-green-500 text-white px-6 py-3 rounded-lg hover:bg-green-600 transition duration-150 ease-in-out"
          >
            check status
          </button>
        </div>
      </div>
    </div>
  );
};

export default OrderSuccess;
