import React, { useState, useEffect } from 'react';
import bannerImage1 from '../Images/tandoori.jpg';
import bannerImage2 from '../Images/noodles.jpg';
import bannerImage3 from '../Images/soups.jpg';


const banners = [
  { image: "https://i.pinimg.com/originals/46/b7/d5/46b7d56445a1675781ba4bd97fd62a35.jpg", title: 'Fresh to your table', subtitle: 'Heavy discounts on raw chicken' },
  { image: bannerImage2, title: 'Delicious Noodles', subtitle: 'Tasty and healthy' },
  { image: bannerImage3, title: 'Hot Soups', subtitle: 'Warm and cozy' },
];

const Banner = () => {
  const [currentBannerIndex, setCurrentBannerIndex] = useState(0);
  const [fadeIn, setFadeIn] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setFadeIn(false);
      setTimeout(() => {
        setCurrentBannerIndex((prevIndex) => (prevIndex + 1) % banners.length);
        setFadeIn(true);
      }, 1000); // Time for fade-out effect
    }, 4000); // Change banner every 4 seconds

    return () => clearInterval(interval); // Clean up the interval on component unmount
  }, []);

  const { image, title, subtitle } = banners[currentBannerIndex];

  return (
    <div className="relative w-full h-80 sm:h-96 overflow-hidden ">
      <img
        src={image}
        alt="Promotional Banner"
        className={`w-full h-full object-cover ${fadeIn ? 'fade-in' : ''}`}
      />
      <div className="banner-overlay"></div>
      <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50">
        <div className={`text-center text-white p-4 ${fadeIn ? 'fade-in' : ''}`}>
          <h2 className="text-2xl md:text-3xl font-bold">{title}</h2>
          <p className="text-lg md:text-xl mt-2">{subtitle}</p>
        </div>
      </div>
    </div>
  );
};

export default Banner;
