// src/components/AdminDashboard.js
import React from 'react';
// import './path-to-nickefox-css/styles.css'; // Nickefox CSS का पथ

const AdminDashboard = () => {
    return (
        <div>
            {/* Nickefox का HTML कंटेंट यहाँ आएगा */}
            <h1>एडमिन डैशबोर्ड में आपका स्वागत है</h1>
        </div>
    );
};

export default AdminDashboard;
