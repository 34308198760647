import React, { useState } from 'react';
import { Button, Form, Card, Alert, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom';
import './AddressPage.css'; // Import custom styles if needed

const AddressPage = () => {
  const [address, setAddress] = useState({
    fullName: '',
    phoneNumber: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    postalCode: '',
    country: 'India', // Set default country to India
  });

  const [submitted, setSubmitted] = useState(false); // New state to handle submission
  const navigate = useNavigate(); // Navigation hook

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAddress({ ...address, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Save the address details (you can save it to the context, state, or make an API call)
    console.log('Address Saved:', address);

    // For simplicity, navigate to the cart page with the address as state
    navigate('/cart', { state: { address } });
  };

  return (
    <div className="container mt-5">
      <Row className="mt-4">
        <Col md={12}>
          <Card className="p-4">
            <Card.Title>Add Delivery Address</Card.Title>
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="formFullName" className="mb-3">
                <Form.Label>Full Name</Form.Label>
                <Form.Control
                  type="text"
                  name="fullName"
                  value={address.fullName}
                  onChange={handleInputChange}
                  placeholder="Enter your full name"
                  required
                />
              </Form.Group>

              <Form.Group controlId="formPhoneNumber" className="mb-3">
                <Form.Label>Phone Number</Form.Label>
                <Form.Control
                  type="text"
                  name="phoneNumber"
                  value={address.phoneNumber}
                  onChange={handleInputChange}
                  placeholder="Enter your phone number"
                  required
                />
              </Form.Group>

              <Form.Group controlId="formAddressLine1" className="mb-3">
                <Form.Label>Address Line 1</Form.Label>
                <Form.Control
                  type="text"
                  name="addressLine1"
                  value={address.addressLine1}
                  onChange={handleInputChange}
                  placeholder="House number, street name"
                  required
                />
              </Form.Group>

              <Form.Group controlId="formAddressLine2" className="mb-3">
                <Form.Label>Address Line 2</Form.Label>
                <Form.Control
                  type="text"
                  name="addressLine2"
                  value={address.addressLine2}
                  onChange={handleInputChange}
                  placeholder="Apartment, suite, unit, building, floor, etc."
                />
              </Form.Group>

              <Form.Group controlId="formCity" className="mb-3">
                <Form.Label>City</Form.Label>
                <Form.Control
                  type="text"
                  name="city"
                  value={address.city}
                  onChange={handleInputChange}
                  placeholder="Enter your city"
                  required
                />
              </Form.Group>

              <Form.Group controlId="formState" className="mb-3">
                <Form.Label>State</Form.Label>
                <Form.Control
                  type="text"
                  name="state"
                  value={address.state}
                  onChange={handleInputChange}
                  placeholder="Enter your state"
                  required
                />
              </Form.Group>

              <Form.Group controlId="formPostalCode" className="mb-3">
                <Form.Label>Postal Code</Form.Label>
                <Form.Control
                  type="text"
                  name="postalCode"
                  value={address.postalCode}
                  onChange={handleInputChange}
                  placeholder="Enter your postal code"
                  required
                />
              </Form.Group>

              <Form.Group controlId="formCountry" className="mb-3">
                <Form.Label>Country</Form.Label>
                <Form.Control
                  type="text"
                  name="country"
                  value={address.country}
                  onChange={handleInputChange}
                  placeholder="Enter your country"
                  required
                />
              </Form.Group>

              <div className="d-flex justify-content-end">
                <Button variant="primary" type="submit">
                  Save & Continue
                </Button>
              </div>
            </Form>
            
            {submitted && (
              <Alert variant="success" className="mt-3">
                Address successfully saved! You can now proceed to the checkout.
              </Alert>
            )}
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default AddressPage;
