import React, { useEffect, useState } from 'react';
import chainiesgravies from "../Images/chainiesgravies.jpg";
import "../index.css"; // Custom CSS file for additional styling
import bannerImage from '../Images/chainiesgravies.jpg'; // Import the new banner image
import '@fortawesome/fontawesome-free/css/all.min.css';
import vegIcon from '../Images/Vegicon.png'; // Import the vegetarian icon
import { useNavigate } from 'react-router-dom';
import AppBar from './AppBar';

const Banner = ({ title, subtitle, image }) => {
  return (
    <div className="relative w-full h-80 sm:h-96 overflow-hidden">
      <img src={image} alt="Promotional Banner" className="w-full h-full object-cover" />
      <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50">
        <div className="text-center text-white p-4">
          <h2 className="text-2xl md:text-3xl font-bold">{title}</h2>
          <p className="text-lg md:text-xl mt-2">{subtitle}</p>
        </div>
      </div>
    </div>
  );
};

const ChiniesList = () => {
  const navigate = useNavigate();
  const initialChinies = [
    {
      name: "Veg Manchurian",
      categary:'chinies',
      price: 250,
      discount: 10,
      description: "Crispy veg balls in spicy manchurian sauce...",
      image: chainiesgravies,
      quality: 4 // Quality rating out of 5
    },
    {
      name: "Hakka Noodles",
        categary:'chinies',
      price: 200,
      discount: 15,
      description: "Stir-fried noodles with vegetables...",
      image: chainiesgravies,
      quality: 5 // Quality rating out of 5
    },
    {
      name: "Sweet and Sour Chicken",
        categary:'chinies',
      price: 350,
      discount: 20,
      description: "Chicken in a tangy sweet and sour sauce...",
      image: chainiesgravies,
      quality: 4 // Quality rating out of 5
    },
  ];

  const [chinies, setChinies] = useState(initialChinies.map((chinies, index) => ({ ...chinies, quantity: 0, index })));
  const [cartItems, setCartItems] = useState([]);

  const increaseQuantity = (index) => {
    const newChinies = [...chinies];
    newChinies[index].quantity += 1;
    setChinies(newChinies);
    updateCartItems(newChinies);
  };

  const decreaseQuantity = (index) => {
    const newChinies = [...chinies];
    if (newChinies[index].quantity > 0) {
      newChinies[index].quantity -= 1;
    }
    setChinies(newChinies);
    updateCartItems(newChinies);
  };

  const updateCartItems = (newChinies) => {
    const newCartItems = newChinies.filter(chinies => chinies.quantity > 0);
    setCartItems(newCartItems);
  };

  const handleAddClick = (index) => {
    const newChinies = [...chinies];
    newChinies[index].quantity = 1;
    setChinies(newChinies);
    updateCartItems(newChinies);
  };

  const handleViewCart = () => {
    navigate('/place-order', { state: { cartItems, chinies } });
  };

  const totalQuantity = cartItems.reduce((sum, item) => sum + item.quantity, 0);
  const totalPrice = cartItems.reduce((sum, item) => sum + (item.price * item.quantity * (1 - item.discount / 100)), 0).toFixed(2);

  const renderStars = (quality) => {
    const stars = [];
    for (let i = 0; i < 5; i++) {
      stars.push(
        <i key={i} className={`fas fa-star ${i < quality ? 'text-yellow-500' : 'text-gray-300'}`}></i>
      );
    }
    return stars;
  };


  return (
    <div className="flex flex-col h-screen">
      <AppBar />
      <div className="flex-1 overflow-auto">
        <Banner title="Delicious Chinese Items" subtitle="Order now and enjoy fresh Chinese items!" image={bannerImage} />
        <div className="text-3xl font-bold mb-4 text-center">Chinese Delights</div>
        <div className="grid grid-cols-1 gap-4">
          {chinies.map((chinies, index) => {
            const discountedPrice = chinies.price - (chinies.price * (chinies.discount / 100));
            return (
              <div key={index} className="card shadow-lg rounded-lg overflow-hidden">
                <div className="relative flex p-4 items-center">
                  <div className="relative sm:w-[20%] h-[90%]">
                    <img src={chinies.image} className="w-full h-full object-cover rounded-md" alt={chinies.name} />
                    <img src={vegIcon} className="absolute top-2 left-2 w-6 h-6" alt="Vegetarian Icon" />
                  </div>
                  <div className="ml-4 flex-1">
                    <div className="flex items-center justify-between">
                      <h5 className="text-lg font-bold">{chinies.name}</h5>
                    </div>
                    <p className="text-gray-700 flex items-center">
                      <span className="line-through text-red-500">₹{chinies.price}</span> {" "}
                      <span className="text-green-500 ml-2">₹{discountedPrice.toFixed(2)}</span>
                      <span className="text-green-500 ml-2">({chinies.discount}%)</span>
                    </p>
                    <div className="flex justify-between items-center mt-2">
                      {chinies.quantity === 0 ? (
                        <button
                          className="px-4 py-2 bg-green-500 text-white rounded"
                          onClick={() => handleAddClick(index)}
                        >
                          Add
                        </button>
                      ) : (
                        <div className="flex items-center space-x-2">
                          <button
                            className="px-2 py-1 bg-red-500 text-white rounded"
                            onClick={() => decreaseQuantity(index)}
                          >
                            -
                          </button>
                          <span>{chinies.quantity}</span>
                          <button
                            className="px-2 py-1 bg-green-500 text-white rounded"
                            onClick={() => increaseQuantity(index)}
                          >
                            +
                          </button>
                        </div>
                      )}
                      <div className="flex items-center mt-2">
                        {renderStars(chinies.quality)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="fixed inset-x-0 bottom-0 bg-red-500 text-white p-2 flex justify-between items-center">
        <span className="text-lg font-bold">₹{totalPrice}</span>
        <button
          onClick={handleViewCart}
          className="text-white py-2 px-4 hover:bg-red-700 border rounded-full border-white flex items-center"
        >
          View Cart ({totalQuantity})
        </button>
      </div>
    </div>
  );
};

export default ChiniesList;
