import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useCart } from '../CartContext';
import { useParams } from 'react-router-dom';
import { server } from './Server';
const AllItem = () => {
  const [mainCourses, setMainCourses] = useState([]);
  const [error, setError] = useState(null);
  const { cart, addToCart, removeFromCart } = useCart();
  const { category } = useParams(); 

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(`${server}/readproduct`, {
          params: { category }
        });
        setMainCourses(response.data);
      } catch (error) {
        console.error('Error fetching products:', error);
        setError('Error fetching products');
      }
    };

    fetchProducts();
  }, [category]);

  const handleAddClick = (index) => {
    const item = mainCourses[index];
    const isInCart = cart.find(cartItem => cartItem._id === item._id);

    if (isInCart) {
      removeFromCart(item._id);
    } else {
      addToCart(item);
    }
  };

  const renderStars = (quality) => {
    const stars = [];
    for (let i = 0; i < quality; i++) {
      stars.push(<span key={i} className="text-yellow-400 text-lg">⭐</span>);
    }
    return stars;
  };

  return (
    <div className="container mx-auto p-6">
      <h1 className="text-3xl font-bold mb-4">
        {category ? category.charAt(0).toUpperCase() + category.slice(1) : 'Category'} Menu List
      </h1>
      {error && <p className="text-red-500 mb-4">{error}</p>}
      <hr className="mb-6 border-gray-300" />
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-10">
        {mainCourses.flatMap((mainCourse, index) => {
          const discountPercentage = mainCourse.productPrice > 0 
            ? ((mainCourse.price - mainCourse.productPrice) / mainCourse.price) * 100 
            : 0;
          const discountedPrice = mainCourse.productPrice;
          const cards = [];

          for (let i = 0; i < mainCourse.quantity; i++) {
            cards.push(
              <div key={`${index}-${i}`} className="card bg-white shadow-md rounded-lg overflow-hidden relative h-80 w-64">
                <div className="relative w-full h-48">
                  <img 
                    src={mainCourse.image ? `http://localhost:5000/${mainCourse.image}` : '/path/to/placeholder.jpg'} 
                    className="w-full h-full object-cover" 
                    alt={mainCourse.name} 
                  />
                  {discountPercentage > 0 && (
                    <div className="absolute top-2 left-2 bg-red-500 text-white px-2 py-1 text-xs rounded-full shadow-md">
                      {discountPercentage.toFixed(0)}%
                    </div>
                  )}
                </div>
                <div className="p-4 h-32">
                  <h5 className="text-lg font-semibold mb-1">{mainCourse.name}</h5>
                  <p className="text-xs text-gray-500 mb-2">
                    Categories: {mainCourse.categories}
                  </p>
                  <p className="text-gray-700 mb-2">
                    {discountPercentage > 0 && (
                      <span className="line-through text-red-500">₹{mainCourse.price.toFixed(2)}</span>
                    )}
                    <br />
                    <span className="text-green-500 text-lg font-bold">₹{discountedPrice.toFixed(2)}</span>
                  </p>
                  <p className="text-gray-500 text-xs mb-2">Quantity: {mainCourse.quantity}</p>
                  <div className="flex items-center space-x-2 mb-2">
                    {renderStars(mainCourse.quality)}
                  </div>
                  <div className="flex items-center justify-between">
                    <button
                      className={`px-4 py-2 ${cart.find(cartItem => cartItem._id === mainCourse._id) ? 'bg-red-500' : 'bg-green-500'} text-white rounded hover:bg-${cart.find(cartItem => cartItem._id === mainCourse._id) ? 'red-600' : 'green-600'} transition duration-200`}
                      onClick={() => handleAddClick(index)}
                      disabled={mainCourse.quantity <= 0}
                    >
                      {cart.find(cartItem => cartItem._id === mainCourse._id) ? 'Remove from Cart' : 'Add to Cart'}
                    </button>
                  </div>
                </div>
              </div>
            );
          }

          return cards;
        })}
      </div>
    </div>
  );
};

export default AllItem;
