import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import AppBar from './AppBar';
import axios from 'axios';

const ConfirmOrder = () => {
  const {phone} = useParams()
  const location = useLocation();
  const navigate = useNavigate();
  const [otp, setOtp] = useState(new Array(4).fill(''));
  const [counter, setCounter] = useState(60);
  const [error, setError] = useState('');
  const phoneNumber = location.state?.phone || '';

  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  const handleChange = (element, index) => {
    if (isNaN(element.value)) return false;
    setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);

    // Focus next input box
    if (element.nextSibling) {
      element.nextSibling.focus();
    }
  };

  const handleSubmit =async (e) => {
    e.preventDefault();
    if (otp.includes('')) {
      setError('Please enter the full OTP.');
      return;
    }
    const otpString = otp.join('');
    try {
      await axios.post('http://localhost:3000/verify-otp', { phone, otp: otpString });
      alert('OTP verified successfully!');
    } catch (error) {
      console.error('Error verifying OTP:', error);
      alert('Failed to verify OTP');
    }
  };
  const resendOtp = () => {
    // Handle resend OTP logic here
    console.log('Resend OTP');
    setCounter(60);
  };

  return (
    <div>
      <AppBar />
      <div className="flex  min-h-screen bg-gray-100">
        <div className="bg-white p-6 rounded-lg shadow-lg w-96">
          <h2 className="text-2xl font-bold mb-4 text-center">Enter OTP sent to '{phoneNumber}'</h2>
          <form onSubmit={handleSubmit} className="space-y-4">
            <div className="flex justify-center space-x-2">
              {otp.map((data, index) => {
                return (
                  <input
                    key={index}
                    type="text"
                    maxLength="1"
                    className="w-12 h-12 text-center border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600"
                    value={data}
                    onChange={(e) => handleChange(e.target, index)}
                    onFocus={(e) => e.target.select()}
                  />
                );
              })}
            </div>
            {error && <p className="text-red-600 text-center">{error}</p>}
            <div className="flex justify-between items-center">
              {counter > 0 ? (
                <span className="text-gray-600">Resend OTP in 0:{counter < 10 ? `0${counter}` : counter} s</span>
              ) : (
                <button type="button" className="text-blue-600 hover:underline" onClick={resendOtp}>
                  Resend OTP
                </button>
              )}
            </div>
            <button
              type="submit"
              className="w-full bg-red-500 text-white px-6 py-3 rounded-lg hover:bg-red-600 transition duration-150 ease-in-out"
            >
              Verify
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ConfirmOrder;
