import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { json, useNavigate } from 'react-router-dom';
import { useCart } from '../CartContext';
import { FaUser, FaEnvelope } from 'react-icons/fa';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal, Button, Spinner } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { server } from './Server';
const UserInformationModal = ({ show, onClose }) => {
  const [name, setName] = useState('');
  const [whatsappNumber, setWhatsappNumber] = useState('');
  const [otp, setOtp] = useState(['', '', '', '']);
  const [otpSent, setOtpSent] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  const [expiryTime, setExpiryTime] = useState(60); // 1 minute in seconds
  const [loading, setLoading] = useState(false); // New state for loading spinner
  const navigate = useNavigate();
  const { setUser, cart } = useCart();
  const [animationClass, setAnimationClass] = useState('slide-up');

  useEffect(() => {
    if (otpSent && expiryTime > 0) {
      const timer = setInterval(() => {
        setExpiryTime((prevTime) => prevTime - 1);
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [otpSent, expiryTime]);

  const handleSendOtp = async () => {
    try {
      setLoading(true); // Start loading spinner
      const response = await axios.post(`${server}/user/sendotp`, { whatsappNumber, name });
      if (response.data.success) {
        setOtpSent(true);
        setExpiryTime(60); // Reset the expiry time
        toast.success('OTP sent to your WhatsApp number.');
      } else {
        toast.error('Failed to send OTP. Please try again.');
      }
    } catch (error) {
      toast.error('Failed to send OTP. Please try again.');
    } finally {
      setLoading(false); // Stop loading spinner
    }
  };

  const handleVerifyOtp = async () => {
    setLoading(true); // Start loading spinner
  
    // Add a 5-second delay
    setTimeout(async () => {
      try {
        const otpString = otp.join('');
        const response = await axios.post(`${server}/verify-otp`, {
          whatsappNumber,
          otp: otpString,
          name, // Include name in the request
          shopId: cart.map(item => item.shop.shopId), // Send shop IDs
        });
  
        if (response.data.success) {
          setOtpVerified(true);
          toast.success('OTP verified successfully.');
          onClose();
          navigate('/cart');
          localStorage.setItem('orderDetails', JSON.stringify({
         cart
          }));

          setUser(whatsappNumber);
        } else {
          toast.error('Invalid OTP. Please try again.');
        }
      } catch (error) {
        toast.error('Failed to verify OTP. Please try again.');
      } finally {
        setLoading(false); // Stop loading spinner
      }
    }, 5000); // 5-second delay
  };
  
  useEffect(() => {
    if (show) {
      setAnimationClass('slide-up');
    } else {
      setAnimationClass('slide-down');
    }
  }, [show]);

  const handleOtpChange = (index, value) => {
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);
    if (index < 3 && value.length === 1) {
      document.getElementById(`otp-${index + 1}`).focus();
    }
  };

  return (
    <>
      {/* Main User Information Modal */}
      <Modal show={show} onHide={onClose} className={` ${show ? 'show' : ''}`}>
        <div
          className={`fixed inset-x-0 bottom-0 bg-white shadow-lg p-4 ${otpSent ? 'translate-y-full' : 'translate-y-full'} ${animationClass}` }
          style={{ zIndex: 1050, borderRadius: '15px 15px 0 0' }}
        >
          {!otpSent ? (
            <div>
              <div className="d-flex justify-content-between align-items-center mb-4">
                <h5 className="text-lg font-bold">User Information</h5>
                <button className="btn btn-danger" onClick={onClose}>Close</button>
              </div>
              <form onSubmit={(e) => { e.preventDefault(); handleSendOtp(); }}>
                <div className="mb-3">
                  <div className="input-group">
                    <span className="input-group-text bg-white border">
                      <FaUser className="text-gray-500" />
                    </span>
                    <input
                      type="text"
                      placeholder="Enter your name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      required
                      className="form-control border-1 p-3 shadow-none"
                    />
                  </div>
                </div>
                <div className="mb-2">
                  <div className="input-group">
                    <span className="input-group-text">
                      <FaEnvelope className="text-3xl shadow-lg" style={{ color: '#d44638' }} />
                    </span>
                    <input
                      type="text"
                      placeholder="Enter your Email "
                      value={whatsappNumber}
                      onChange={(e) => setWhatsappNumber(e.target.value)}
                      required
                      className="form-control border-1 p-3 shadow-none"
                    />
                  </div>
                  {whatsappNumber && (
                    <small className="form-text text-muted">
                      All information will be sent to this Email Address.
                    </small>
                  )}
                </div>
                <button
                  type="submit"
                  className="btn w-100"
                  style={{ backgroundColor: '#FF9800', color: '#fff' }}
                  disabled={loading} // Disable button while loading
                >
                  {loading ? <Spinner animation="border" size="sm" /> : 'Send OTP'}
                </button>
              </form>
            </div>
          ) : (
            <div className="otp-modal-content">
              <Modal.Header closeButton>
                <Modal.Title className='text-center text-lg w-full'>
                  Verify OTP
                  {loading && <Spinner animation="border" size="sm" className="ms-2" />}
                </Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <div className="mb-3">
                  <div className="d-flex justify-content-center">
                    {/* OTP Input Fields */}
                    {otp.map((digit, index) => (
                      <input
                        key={index}
                        id={`otp-${index}`}
                        type="text"
                        value={digit}
                        onChange={(e) => handleOtpChange(index, e.target.value)}
                        maxLength={1}
                        className="form-control text-center rounded mx-2"
                        style={{ width: '40px', height: '40px' }}
                      />
                    ))}
                  </div>
                </div>
                <p className="expiry-time text-center mt-2">OTP expires in: {expiryTime} seconds</p>
                <Button
                  variant="primary"
                  onClick={handleVerifyOtp}
                  style={{ backgroundColor: '#FF9800', borderColor: '#FF9800' }}
                  className='w-100 p-3 mt-1'
                  disabled={expiryTime === 0} // Disable button if time expires
                >
                  Verify OTP
                </Button>
                {expiryTime === 0 && (
                  <Button
                    variant="secondary"
                    onClick={handleSendOtp}
                    className='w-100 p-3 mt-2'
                  >
                    Resend OTP
                  </Button>
                )}
              </Modal.Body>
            </div>
          )}
        </div>
      </Modal>

      {/* Toast Notification Container */}
      <ToastContainer />
    </>
  );
};

export default UserInformationModal;
