import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaUtensils, FaArrowLeft } from 'react-icons/fa';
import { Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './ShopsList.css'; // Add custom styles here
import { useNavigate } from 'react-router-dom';
import { server } from './Server';
// Import Google Fonts in index.html or use a link tag in your component
// <link href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap" rel="stylesheet">

const ShopsList = () => {
  const [shops, setShops] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    axios.get(`${server}/addshop`)
      .then(response => setShops(response.data))
      .catch(error => console.error('Error fetching shops:', error));
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setShops(prevShops => prevShops.map(shop => {
        const isOpen = checkShopStatus(shop.openingTime, shop.closingTime);
        return {
          ...shop,
          timeRemaining: isOpen ? calculateTimeRemaining(shop.closingTime) : null,
          nextOpening: !isOpen ? calculateNextOpening(shop.openingTime) : null,
        };
      }));
    }, 1000);
    return () => clearInterval(interval);
  }, [shops]);

  const calculateTimeRemaining = (closingTime) => {
    const currentTime = new Date();
    const close = new Date();
    const [hours, minutes] = closingTime.split(':').map(Number);
    close.setHours(hours);
    close.setMinutes(minutes);
    close.setSeconds(0);

    const timeDiff = close - currentTime;
    if (timeDiff <= 0) return null;

    const hoursRemaining = Math.floor(timeDiff / (1000 * 60 * 60));
    const minutesRemaining = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
    const secondsRemaining = Math.floor((timeDiff % (1000 * 60)) / 1000);

    return `${hoursRemaining}h ${minutesRemaining}m ${secondsRemaining}s`;
  };

  const calculateNextOpening = (openingTime) => {
    const currentTime = new Date();
    const nextOpen = new Date();
    const [hours, minutes] = openingTime.split(':').map(Number);
    nextOpen.setHours(hours);
    nextOpen.setMinutes(minutes);
    nextOpen.setSeconds(0);

    if (nextOpen < currentTime) {
      nextOpen.setDate(nextOpen.getDate() + 1);
    }

    const timeDiff = nextOpen - currentTime;
    if (timeDiff <= 0) return null;

    const hoursRemaining = Math.floor(timeDiff / (1000 * 60 * 60));
    const minutesRemaining = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
    const secondsRemaining = Math.floor((timeDiff % (1000 * 60)) / 1000);

    return `${hoursRemaining}h ${minutesRemaining}m ${secondsRemaining}s`;
  };

  const checkShopStatus = (openingTime, closingTime) => {
    const currentTime = new Date();
    const open = new Date();
    const close = new Date();

    const [openHours, openMinutes] = openingTime.split(':').map(Number);
    const [closeHours, closeMinutes] = closingTime.split(':').map(Number);

    open.setHours(openHours);
    open.setMinutes(openMinutes);
    open.setSeconds(0);

    close.setHours(closeHours);
    close.setMinutes(closeMinutes);
    close.setSeconds(0);

    return currentTime >= open && currentTime <= close;
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <div className="container my-5">
      <header className="fixed-top bg-light py-3 shadow-sm">
        <div className="container d-flex justify-content-between align-items-center">
          <Button variant="link" onClick={handleGoBack} className="p-0" style={{ color: 'gray' }}>
            <FaArrowLeft style={{ fontSize: '1.5rem' }} />
          </Button>
          <h1 className="mb-0 text-center text-uppercase" style={{ fontFamily: 'Roboto, sans-serif', fontWeight: 700 }}>Our Shops</h1>
        </div>
      </header>

      <div className="row mt-5 pt-5">
        {shops.map(shop => (
          <div key={shop._id} className="col-md-4 col-sm-6 mb-4">
            <div className="shop-card card h-100 shadow-sm">
              <img src={`http://localhost:5000/${shop.image}`} alt={shop.name} className="card-img-top shop-image" />
              <div className="card-body d-flex flex-column">
                <h2 className="card-title shop-name" style={{ fontFamily: 'Roboto, sans-serif', fontWeight: 500 }}>{shop.name}</h2>
                <p className="card-text shop-location"><strong>Location:</strong> {shop.location}</p>
                <p className="card-text shop-owner"><strong>Owner:</strong> {shop.owner}</p>
                <p className="card-text shop-description">{shop.description}</p>
                <p className={`shop-status ${checkShopStatus(shop.openingTime, shop.closingTime) ? 'open' : 'closed'} mt-auto`}>
                  {checkShopStatus(shop.openingTime, shop.closingTime) ? 'OPEN NOW' : 'CURRENTLY CLOSED'}
                </p>
                {checkShopStatus(shop.openingTime, shop.closingTime) ? (
                  <p className="card-text shop-countdown">
                    Time until closing: {shop.timeRemaining}
                  </p>
                ) : (
                  <p className="card-text shop-next-opening">
                    Next Opening In: {shop.nextOpening}
                  </p>
                )}
                <p className="card-text shop-hours">
                  <strong>Opening Hours:</strong> {shop.openingTime} - {shop.closingTime}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ShopsList;
