// AdminOrderLocation.js
import React, { useEffect, useState } from 'react';
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
import axios from 'axios';
import { server } from './Server';

const AdminOrderLocation = () => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: 'your_google_maps_api_key',  // Add your Google Maps API key here
  });

  const [order, setOrder] = useState(null);

  useEffect(() => {
    const fetchOrder = async () => {
      try {
        const response = await axios.get(`${server}/order/readorder`);  // Fetch order details including location
        setOrder(response.data.order);
      } catch (error) {
        console.error('Failed to fetch order details:', error);
      }
    };

    fetchOrder();
  }, []);

  if (!isLoaded) return <div>Loading...</div>;
  if (!order) return <div>Loading order details...</div>;

  return (
    <div>
      <GoogleMap
        zoom={15}
        center={{ lat: order.location.lat, lng: order.location.lng }}
        mapContainerStyle={{ width: '100%', height: '400px' }}
      >
        {/* Marker for the customer's location */}
        <Marker position={{ lat: order.location.lat, lng: order.location.lng }} />
      </GoogleMap>
    </div>
  );
};

export default AdminOrderLocation;
