import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { MdOutlineMenu } from 'react-icons/md';
import { BsX } from 'react-icons/bs';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Menu.css'; // Custom styles for animations
import { useCart } from '../CartContext';
import UserInformationModal from './UserInformationPage';
import { server } from './Server';

const CombinedComponent = () => {
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [showMenu, setShowMenu] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const categoryRefs = useRef({});
  const { cart, addToCart, removeFromCart } = useCart();
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [showCartBar, setShowCartBar] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [lastAddedShop, setLastAddedShop] = useState(null); // State to track last added shop
  const [expandedProductId, setExpandedProductId] = useState(null);

  useEffect(() => {
    axios.get(`${server}/readproduct`)
      .then(response => {
        const fetchedProducts = response.data;
        setProducts(fetchedProducts);

        // Extract unique categories from products
        const uniqueCategories = [...new Set(fetchedProducts.map(product => product.categories).flat())];
        setCategories(uniqueCategories);

        // Initialize refs for each category
        uniqueCategories.forEach(category => {
          categoryRefs.current[category] = React.createRef();
        });
      })
      .catch(error => console.error('Error fetching products:', error));
  }, []);

  useEffect(() => {
    const cartItems = cart.reduce((acc, item) => {
      acc.totalQuantity += item.quantity;
      acc.totalPrice += item.productPrice * item.quantity;
      return acc;
    }, { totalQuantity: 0, totalPrice: 0 });

    setTotalQuantity(cartItems.totalQuantity);
    setTotalPrice(cartItems.totalPrice);

    // Show or hide cart bar based on totalPrice
    setShowCartBar(cartItems.totalPrice > 0);
  }, [cart]);

  const handleMenuClick = () => {
    setShowMenu(!showMenu);
  };

  const handleCancelClick = () => {
    setShowMenu(false);
  };

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    setShowMenu(false);

    // Scroll to the selected category section
    if (categoryRefs.current[category]) {
      categoryRefs.current[category].current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleAddClick = (product) => {
    addToCart(product);
    setLastAddedShop(product.shop._id); // Update the last added shop
    setShowMenu(false); // Close the menu after adding item
    setShowCartBar(true);
  };

  const increaseQuantity = (product) => {
    addToCart(product); // Increase quantity in the context
  };

  const decreaseQuantity = (productId) => {
    removeFromCart(productId);
  };

  // Group products by category
  const categoryItems = categories.reduce((acc, category) => {
    acc[category] = products.filter(product => Array.isArray(product.categories) && product.categories.includes(category));
    return acc;
  }, {});

  const handleCloseModal = () => setShowModal(false);

  return (
    <div className="">
      <UserInformationModal show={showModal} onClose={handleCloseModal} />

      {/* Menu Button */}
      <div className="menu-button-container">
        <button className="menu-btn" onClick={handleMenuClick}>
          <MdOutlineMenu size={30} />
        </button>
      </div>

      {/* Menu Overlay */}
      <div className={`menu-overlay ${showMenu ? 'show' : ''}`} onClick={handleCancelClick}>
        <div
          className={`menu-container ${showMenu ? 'slide-up' : 'slide-down'}`}
          onClick={(e) => e.stopPropagation()} // Prevent click from closing menu
        >
          <div className="menu-header d-flex justify-content-between align-items-center mb-4">
            <h1 className="menu-title mb-0 text-center text-xl">Categories</h1>
            <button
              className="btn btn-secondary rounded-circle p-2 cancel-btn"
              onClick={handleCancelClick}
            >
              <BsX size={24} />
            </button>
          </div>

          <div className="text-center">
            {categories.map((category, index) => (
              <div
                key={index}
                className="cursor-pointer card mx-auto"
                onClick={() => handleCategoryClick(category)}
              >
                <div className="">
                  <p className="card-title text-uppercase text-dark">{category}
                    <hr />
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Product List */}
      <div className="flex-1 overflow-auto p-4">
        {categories.map((category, index) => (
          <div key={index} ref={categoryRefs.current[category]} className="my-products overflow-hidden mb-4">
            <h3 className="product-use text-xl font-bold mt-2 text-center"> {category} </h3>
            <div className="products-cards grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-2">
              {categoryItems[category]?.map((product, index) => {
                // Calculate discounted price if needed
                const discountedPrice = product.productPrice || (product.price - (product.price * (product.discount / 100)));
                const discountPercentage = product.productPrice 
                ? ((product.price - product.productPrice) / product.price * 100).toFixed(2)
                : product.discount;

                // Check if product is already in cart
                const isProductInCart = cart.find(item => item._id === product._id);

                // Check if the product's shop matches the last added shop
                const isButtonDisabled = lastAddedShop && product.shop._id !== lastAddedShop ;

                return (
                  <div key={index} className="product-box">
                    <div className="flex items-center gap-2 justify-between w-full">
                      {/* Product Details */}
                      <div className="Product-container">
                        <h5 className="text-lg font-bold ">{product.name}</h5>
                        <p className="text-gray-700 mb-2">
                          <span className="line-through text-red-500">₹{product.price}</span>{" "}
                          <span className="text-green-500 ml-2">₹{discountedPrice.toFixed(2)}</span>
                        </p>
                        <p className="text-gray-600">
                          {product.description.slice(0, 50)}...
                        </p>
                      </div>

                      {/* Image Container */}
                      <div className="relative myimage-container h-32 overflow-hidden bg-gray-100">
                        {/* Image Wrapper */}
                        <div className="relative h-full w-full rounded overflow-hidden">
                          <img
                            src={`http://localhost:5000/${product.image}`}
                            className="w-full h-full object-cover"
                            alt={product.name}
                          />
                        </div>

                        {/* Button Container */}
                        <div className="absolute bottom-0 left-0 w-full p-1 bg-white border-t border-gray-200 flex items-center justify-center space-x-2">
                          {isProductInCart ? (
                            <div className="flex items-center space-x-2 bg-white rounded-t-lg border border-gray-200 shadow-lg">
                              <button
                                className="px-3 py-1 bg-red-500 text-white rounded-l-lg hover:bg-red-600 transition"
                                onClick={() => decreaseQuantity(product._id)}
                              >
                                -
                              </button>
                              <span className="">
                                {isProductInCart.quantity}
                              </span>
                              <button
                                className="px-3 py-1 bg-green-500 text-white rounded-r-lg hover:bg-green-600 transition"
                                onClick={() => increaseQuantity(product)}
                              >
                                +
                              </button>
                            </div>
                          ) : (
                            <button
                            className={`px-4 py-2 rounded transition shadow-md ${
                              isButtonDisabled
                                ? 'bg-white text-black cursor-not-allowed'
                                : 'bg-orange-500 text-white hover:bg-blue-600'
                            }`}
                            onClick={() => !isButtonDisabled && handleAddClick(product)}
                            disabled={isButtonDisabled} // Disable if from another shop
                          >
                            {isButtonDisabled ? 'Not Available' : 'Add'}
                          </button>
                          
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        ))}
      </div>

    

  {/* Footer with Cart Summary */}
  {showCartBar && (
        <div className="fixed inset-x-0 bottom-0 right-0 w-100 left-0 bg-red-500 text-white p-2 flex justify-between items-center">
          <span className="text-lg font-bold">₹{totalPrice.toFixed(2)}</span>
          <button
            onClick={() => setShowModal(true)}
            className="text-white py-2 px-4 hover:bg-red-700 border rounded-full border-white flex items-center"
          >
          view cart {totalQuantity}
          </button>
        </div>
      )}
    </div>
  );
};

export default CombinedComponent;
