import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AppBar from './AppBar';
import axios from 'axios';

const PlaceOrder = () => {
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {

      await axios.post('http://localhost:3000/sendotp', { phone }).then((res)=>{
if(res.data=="send"){

  alert('OTP sent!');
  navigate(`/order-confirmation/${phone}`, { state: { phone } });
}
      })
    } catch (error) {
      console.error('Error sending OTP:', error);
      alert('Failed to send OTP');
    }
  };


  return (
    <div>
      <AppBar />
      <div className="flex  min-h-screen bg-gray-100">
        <div className="bg-white p-6 rounded-lg shadow-lg">
          <h2 className="text-2xl font-bold mb-4 text-center">Confirm your details</h2>
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label htmlFor="name" className="block text-gray-700">Name</label>
              <input
                type="text"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600"
                required
              />
            </div>
            <div>
              <label htmlFor="phone" className="block text-gray-700">Phone</label>
              <div className="relative">
                <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                  <svg className="w-6 h-6 text-green-500" fill="currentColor" viewBox="0 0 24 24">
                    <path d="M21.71 20.29l-3.39-3.39a1 1 0 00-1.41 0l-1.53 1.54a1 1 0 01-1.41 0l-6.25-6.26a1 1 0 010-1.41l1.54-1.53a1 1 0 000-1.41L3.71 2.29a1 1 0 00-1.41 0L1.29 3.29a1 1 0 000 1.41l3.39 3.39a1 1 0 010 1.41L3.29 10.53a1 1 0 000 1.41l6.25 6.26a1 1 0 001.41 0l1.53-1.54a1 1 0 011.41 0l3.39 3.39a1 1 0 001.41 0l1.29-1.29a1 1 0 000-1.41z" />
                  </svg>
                </span>
                <input
                  type="tel"
                  id="phone"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  className="w-full pl-12 px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600"
                  required
                />
              </div>
            </div>
            <div className="flex items-center">
              <input
                type="checkbox"
                id="whatsappUpdates"
                className="mr-2"
              />
              <label htmlFor="whatsappUpdates" className="text-gray-600">Yes, I want to receive important information & updates on my WhatsApp</label>
            </div>
            <button
              type="submit"
              className="w-full bg-red-500 text-white px-6 py-3 rounded-lg hover:bg-red-600 transition duration-150 ease-in-out"
            >
              Confirm
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default PlaceOrder;
