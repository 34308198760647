import React, { createContext, useContext, useState, useCallback, useEffect } from 'react';

// Create a Context for the cart
const CartContext = createContext();

// Create a Provider component
export const CartProvider = ({ children }) => {
  const [cart, setCart] = useState([]);
  const [user, setUser] = useState(null);
  const [orderStatus, setOrderStatus] = useState('');

  // Load cart from localStorage on mount
  useEffect(() => {
    const savedCart = localStorage.getItem('cart');
    if (savedCart) {
      setCart(cart);
    }
  }, []);

  // Save cart to localStorage whenever it changes
  useEffect(() => {
    console.log('Updating localStorage with cart:', cart); // Debugging line
    localStorage.setItem('cart', cart);
  }, [cart]);

  const updateCartOrderId = (orderId) => {
    const updatedCart = cart.map(item => ({
      ...item,
      orderId, // Associate orderId with each cart item
    }));
    setCart(updatedCart);
  };

  const updateQuantity = (productId, quantity) => {
    setCart(prevCart =>
      prevCart.map(item =>
        item._id === productId ? { ...item, quantity } : item
      )
    );
  };

  const removeItem = (productId) => {
    setCart(prevCart => prevCart.filter(item => item._id !== productId));
  };

  const addToCart = useCallback((product) => {
    setCart(prevCart => {
      const existingItem = prevCart.find(item => item._id === product._id);
      
      if (existingItem) {
        return prevCart.map(item =>
          item._id === product._id
            ? { ...item, quantity: item.quantity + 1 }
            : item
        );
      } else {
        return [...prevCart, { ...product, quantity: 1 }];
      }
    });
  }, []);

  const removeFromCart = useCallback((productId) => {
    setCart(prevCart => {
      const existingItem = prevCart.find(item => item._id === productId);
      
      if (existingItem) {
        if (existingItem.quantity > 1) {
          return prevCart.map(item =>
            item._id === productId
              ? { ...item, quantity: item.quantity - 1 }
              : item
          );
        } else {
          return prevCart.filter(item => item._id !== productId);
        }
      }
      
      return prevCart;
    });
  }, []);

  const clearCart = () => {
    setCart([]);
    setOrderStatus('Order Placed Successfully');
  };

  return (
    <CartContext.Provider value={{ user, clearCart, setUser, cart, addToCart, removeFromCart, updateQuantity, removeItem, orderStatus, updateCartOrderId }}>
      {children}
    </CartContext.Provider>
  );
};

// Custom hook to use the CartContext
export const useCart = () => {
  return useContext(CartContext);
};

