import React, { useState } from 'react';
import { Button, Card, Spinner } from 'react-bootstrap';
import { FaRupeeSign } from 'react-icons/fa';
import 'bootstrap/dist/css/bootstrap.min.css';
// import './PaymentPage.css'; // Import custom CSS if needed

const PaymentPage = () => {
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('cod');
  const [loading, setLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const totalPay = 1200; // Replace with actual total amount

  const handleCheckout = () => {
    setLoading(true);
    // Handle checkout logic
    setTimeout(() => {
      setLoading(false);
      alert('Payment processing...');
    }, 2000);
  };

  const handlePopupOpen = () => setShowPopup(true);
  const handlePopupClose = () => setShowPopup(false);

  return (
    <div className="payment-page">
      <Card.Footer className="d-flex fixed-footer justify-content-between">
        <div className="total-price d-flex align-items-center" style={{ color: '#FF6F00' }}>
          <FaRupeeSign style={{ marginRight: '5px' }} />
          <span>{totalPay.toFixed(2)}</span>
        </div>
        <Button
          variant="primary"
          onClick={handlePopupOpen}
          className="me-2"
          disabled={loading}
        >
          {loading ? <Spinner animation="border" size="sm" /> : 'MAKE PAYMENT'}
        </Button>
      </Card.Footer>

      {/* Popup Component */}
      {showPopup && (
        <div className="payment-popup">
          <Card className="payment-method-card">
            <Card.Body>
              <Card.Title className="payment-title">Select Payment Method:</Card.Title>
              <hr />
              <div className="d-flex flex-column">
                {/* Payment Method Options */}
                <div className="payment-option-box">
                  <label className="payment-option">
                    <input
                      type="radio"
                      name="paymentMethod"
                      value="cod"
                      checked={selectedPaymentMethod === 'cod'}
                      onChange={(e) => setSelectedPaymentMethod(e.target.value)}
                    />
                    Cash on Delivery
                  </label>
                </div>
                <div className="payment-option-box mt-3">
                  <label className="payment-option">
                    <input
                      type="radio"
                      name="paymentMethod"
                      value="card"
                      checked={selectedPaymentMethod === 'card'}
                      onChange={(e) => setSelectedPaymentMethod(e.target.value)}
                    />
                    Credit/Debit Card
                  </label>
                </div>
              </div>
              <div className="d-flex justify-content-end mt-3">
                <Button variant="secondary" onClick={handlePopupClose}>Close</Button>
                <Button variant="primary" className="ms-2" onClick={handleCheckout}>
                  {loading ? <Spinner animation="border" size="sm" /> : 'Confirm Payment'}
                </Button>
              </div>
            </Card.Body>
          </Card>
        </div>
      )}
    </div>
  );
};

export default PaymentPage;
