import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Menu from './Components/Menu'; // Adjust path as needed
import PizzaList from './Components/pizzalist'; // Adjust path as needed
import Body from './Components/Home';
import TandooriList from './Components/tandoorilist';
import ChiniesList from './Components/chinieslist';
import MainCourseList from './Components/maincourselist';
import PaymentPage from './Components/PaymentPage';
import CartPage from './Components/cartPage';
import PlaceOrder from './Components/PlaceOrder';
import ConfirmOrder from './Components/Confirmorder';
import OrderSuccess from './Components/OrderSuccess';
import AddItem from './Components/AddItem';
import GetItem from './Components/GetItem';
import Dashboard from './Components/AdminDashboard';
// import { CartProvider } from './Components/CartProvider';
import CollectionDataPage from './Components/CollectionDataPage'
import UserInformationPage from './Components/UserInformationPage';
import ShopsList from './Components/Shops';
import AddressPage from './Components/AddressPage';
import StatusPage from './Components/StatusPage';
import LiveLocation from './Components/LiveLocation'
const App = () => {
  return (
    // <CartProvider>

    
    <Router>
   
      <Routes>

        <Route path="/" element={<Body />} />

        <Route path="/Menu" element={<Menu />} />
        <Route path="/shops" element={<ShopsList />} />
        <Route path="/collection/:collectionName" element={<CollectionDataPage />} />
        <Route path="/Menu/:pizza" element={<PizzaList />} />
        <Route path="/Menu/tandoori" element={< TandooriList />} />
        <Route path="/user-informatation" element={<UserInformationPage />} />
        <Route path="/Menu/chinies" element={< ChiniesList />} />
        <Route path="/Menu/maincourse" element={< MainCourseList />} />
        <Route path="/payment" element={<PaymentPage />} />
        <Route path="/cart" element={<CartPage />} />
        <Route path="/address/:user" element={<AddressPage/>} />
        <Route path="/place-order" element={<PlaceOrder />} />
        <Route path="/order-confirmation/:phone" element={<ConfirmOrder />} />
        <Route path="/order-success" element={<OrderSuccess />} />

        <Route path="/additem" element={<AddItem/>} />
        <Route path="/getitem" element={<GetItem/>} />
        <Route path="/dashboard" element={<Dashboard/>} />
        <Route path="/user/status" element={<StatusPage/>} />
        <Route path="/user/livelocation" element={<LiveLocation/>} />

      </Routes>
    </Router>
    // </CartProvider>
  );
}

export default App;

