import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { server } from './Server';
const OrderDetails = () => {
  const [order, setOrder] = useState(null);
  const [orderstatus,setorderstatus] = useState([])
  const navigate = useNavigate();
  useEffect(() => {
    
    const orderDetails = localStorage.getItem('orderDetails');
    if (orderDetails) {
      const myorderId = JSON.parse(orderDetails)
  
    const GetData =async  ()=>{
      const getmydata = await  axios.get(`${server}/order/readorder`);
      const filtered = getmydata.data
      const filterdata = filtered.filter((orderkiId)=>
        
       orderkiId._id === myorderId.orderId
        
      )
      setorderstatus(filterdata)
      setOrder(JSON.parse(orderDetails));
  }
  GetData()
}
  }, []);

  const handleContinueShopping = () => {
    navigate('/'); // Navigate to the shop or home page
  };

  const handleCancelOrder = async () => {
    if (order) {
      try {
        // Assuming you have an endpoint to cancel orders
        const response = await axios.put(`${server}/order/cancelorder/${order.orderId}`);
        if (response.status === 200) {
          alert('Order cancelled successfully.');
          localStorage.removeItem('orderDetails');
          navigate('/'); // Navigate to the shop or home page
        } else {
          alert('Failed to cancel order. Please try again.');
        }
      } catch (error) {
        console.error('Error canceling order:', error);
        alert('Failed to cancel order. Please try again.');
      }
    }
  };

  const handleOnlinePayment = () => {
    // Logic for online payment if COD selected
    if (order && order.paymentMethod === 'cod') {
      // Redirect or open payment gateway here
      navigate('/payment');
    }
  };

  return (
    <div className="p-4">
       {/* <h2 className="text-xl font-bold mb-4"></h2> */}
      {order ? (
   
        <div className="max-w-lg mx-auto bg-white shadow-md rounded-lg p-6">
    <h2 className="text-xl font-bold mb-4">Order Details</h2>
  
    <div className='flex justify-between'>  
          
           <div>
             <p className="font-semibold ">Order ID:</p>
<p>{order.orderNo}</p></div>
            <div className='flex flex-col items-center'>
<div>
<p className="font-semibold ">Order Items:</p>

{
  order.items.map((item)=>{
return <>
  {item.productName}
</>

  })
}
</div>
    </div>
          </div>
          <div className="mb-4">
            <p className="font-semibold">Total Amount:</p>
            <p>₹{order.totalAmount}</p>
          </div>
          <div className="mb-4">
            <p className="font-semibold">Payment Method:</p>
            <p>{order.paymentMethod}</p>
          </div>
       <div className='flex justify-content-around'> 
       <div className="mb-4">
       <div>
<p className="font-semibold ">Order Items:</p>

{
  orderstatus.map((item)=>{
return <>
  {item.orderStatus}
</>

  })
}
</div>
          </div>
          <div className="mb-4">
            <p className="font-semibold">Payment Status:</p>
            <p>{order.paymentStatus}</p>
          </div>
       </div>
          <div className="flex space-x-4 gap-4">
            <button 
              onClick={handleContinueShopping} 
              className="bg-primary text-white py-2 mr-4 px-4 rounded hover:bg-blue-600"
            >
              Continue Shopping
            </button>
            {/* {order.paymentMethod === 'cod' && (
              <button 
                onClick={handleOnlinePayment} 
                className="bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600"
              >
                Online Payment
              </button> */}
            {/* )} */}
            {/* <button 
              onClick={handleCancelOrder} 
              className="bg-red-500 text-white py-2 px-4 rounded hover:bg-red-600"
            >
              Cancel Order
            </button> */}
          </div>
        </div>
      ) : (
        <div className="text-center text-lg">No order details available</div>
      )}
    </div>
  );
};

export default OrderDetails;
