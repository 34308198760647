import React, { useState } from 'react';
import { FaSearch, FaUtensils } from 'react-icons/fa'; 
import 'bootstrap/dist/css/bootstrap.min.css'; 
import './mystyle.css';
import { Link } from 'react-router-dom';
import { FormControl } from 'react-bootstrap';

const Navbar = () => {
  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light bg-danger">
        <div className="container">
          <a className="navbar-brand text-white d-flex align-items-center" href="/">
            <FaUtensils className="me-2" />
            <span className="fs-4">Foodie</span>
          </a>
          <div className="mx-auto">
            <div className="d-flex align-items-center bg-white px-3 py-2 rounded shadow-sm">
              <FaSearch className="text-muted me-2" />
              <FormControl
                type="text"
                className="border-0 shadow-none"
                placeholder="Search for Items"
              />
            </div>
          </div>
          <div className="ml-auto">
            <h4 className="text-white mb-0">
              <Link to="/shops" className="text-white text-decoration-none">
                Shops
              </Link>
            </h4>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
